<template>
  <div eagle-form-item="file">
    <file-uploader
      @addFile="addFile"
      :type="fileType"
      :maxQuantity="maxQuantity"
      :hideFileList="formDataItem.hideFileList === true"
      :customMaxFileSizeMb="formDataItem.customMaxFileSizeMb"
      :disabled="disabled"
      v-model="data"
    ></file-uploader>
  </div>
</template>

<script>
import formItemMixin from '@/components/form/mixins/formItemMixin'
export default {
  mixins: [formItemMixin],
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.data = window.eagleLodash.cloneDeep(this.storeData)
    },
    addFile(file) {
      if(typeof this.formDataItem.onFileUploaded != 'function') return
      this.formDataItem.onFileUploaded(file)
    },
  },
  computed: {
    fileType() {
      return this.formDataItem.fileType || 'default'
    },
    maxQuantity() {
      return this.formDataItem.maxQuantity || 0
    },
  },
  watch: {
    data: {
      deep: true,
      handler() {
        if(window.eagleLodash.isEqual(this.data, this.storeData)) return
        this.sync()
      },
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
